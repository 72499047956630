import request from "@/utils/request";
import { XHR_URL } from "@/utils/config"

// 查询设备类型
export const machinecatasSelect = data => request(`${XHR_URL}/api/v1/machinecatas`, data, "GET");
// 新增设备类型
export const machinecatasInsert = data => request(`${XHR_URL}/api/v1/machinecatas`, data, "POST");
// 修改设备类型
export const machinecatasUpdate = data => request(`${XHR_URL}/api/v1/machinecatas`, data, "PUT");
// 删除设备类型
export const machinecatasDelete = data => request(`${XHR_URL}/api/v1/machinecatas`, data, "DELETE");



// 查询设备
export const machinesSelect = data => request(`${XHR_URL}/api/v1/machines`, data, "GET");
// 设备列表-导出
export const machinesExport = data => request(`${XHR_URL}/api/v1/machines/export`, data, "GET");


// 设备详情
export const machinesInfo = data => request(`${XHR_URL}/api/v1/deviceinfo`, data, "GET");

// 新增设备
export const machinesInsert = data => request(`${XHR_URL}/api/v1/machines`, data, "POST");
// 修改设备
export const machinesUpdate = data => request(`${XHR_URL}/api/v1/machines`, data, "PUT");
// 删除设备
export const machinesDelete = data => request(`${XHR_URL}/api/v1/machines`, data, "DELETE");
// 分配合伙人
export const machinesFranchi = data => request(`${XHR_URL}/api/v1/machines/franchi`, data, "PUT");
// 分配加盟商
export const machinesOperator = data => request(`${XHR_URL}/api/v1/machines/operator`, data, "PUT");
// 启用
export const machinesValid = data => request(`${XHR_URL}/api/v1/machines/valid`, data, "PUT");
// 停用
export const machinesInvalid = data => request(`${XHR_URL}/api/v1/machines/invalid`, data, "PUT");
// 重启
export const machinesReboot = data => request(`${XHR_URL}/api/v1/machines/reboot`, data, "PUT");
// 重启秘钥
export const rebootSecret = data => request(`${XHR_URL}/api/v1/machines/secret`, data, "PUT");
//上传设备日志
export const machineLogs = data => request(`${XHR_URL}/api/v1/machines/vmlogs`, data, "GET");

