<template>
	<div class="m_common_content">
   	 	<div class="m_common_top">
   	 		<div class="m_checkdata_content">
   	 			设备详情
   	 		</div>
		</div>
		<div class="m_common_middle">
			<div style="display: flex;margin-bottom: 10px;">
				<h1 class="h1s">设备状态：
					<p v-if="vminfo.vmOperateStatus=== 'Normal'" style="display: inline-block;">
						<p v-if="vminfo.vmFaultStatus === 'Invalid'" style="display: inline-block;">
							<i style="color: #ff0000">故障(
							<i v-if="vminfo.vmMachineStatus === 'Online'" style="color: #62c462">在线</i>
							<i v-else style="color: #666">离线</i>
							)</i>
						</p>
						<p v-else style="display: inline-block;">
							<i v-if="vminfo.vmMachineStatus === 'Online'" style="color: #62c462">在线</i>
							<i v-else style="color: #666">离线</i>
						</p> 
					</p>
					<p v-if="vminfo.vmOperateStatus=== 'Stopped'" style="display: inline-block;"> 
						<i style="color: #666">停售</i>
					</p>
				</h1>
				<h1 class="h1s"><font color="#19C104">{{vminfo.vmTtemperature}}°C</font></h1>
				<h1 class="h1s">机器管理员:{{userInfo.adminName}}</h1>
				<h1 class="h1s">系统版本号:{{vminfo.appVersion}}</h1>
			</div>
			 <el-table :data="[vminfo]" style="margin-bottom: 10px;">
          <el-table-column
            prop="goodsName"
            label="省"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="goodsCategory"
            label="市"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="goodsPrice"
            label="区县"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="address"
            label="详细地址"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="positionName"
            label="点位名称"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="vmCode"
            label="设备编号"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="goodsPrice"
            label="加盟商"
            show-overflow-tooltip
          >
          </el-table-column>
        <el-table-column label="运营状态" show-overflow-tooltip>
            <template slot-scope="scope">
				<i v-if="scope.row.vmOperateStatus=== 'Normal'" style="color: #62c462">正常</i>
				<i v-else style="color: #666">停售</i>
            </template>
        </el-table-column>
        </el-table>
        <h1  class="h1-title">设备详细状态</h1>
        <div class="vm-states-list">
        	<p v-for="item in vminfo.sensorStatus">{{item.sensorDefineName}}: {{item.sensorDefineStatus}} </p>
        </div>
		</div>
		<div class="m_common_bottom">
			<el-button @click="machinesInvalid" class="m_add m_btn_dange" v-if="vminfo.vmOperateStatus ==='Normal'">停售</el-button>
			<el-button @click="machinesValid" class="m_add m_btn_common" v-else>恢复销售</el-button>
			<el-button @click="machinesReboot" class="m_add m_btn_common">设备重启</el-button>
			<el-button @click="gowarn" class="m_add m_btn_common">本地日志</el-button>
			<el-button @click="cancel">返回</el-button>
		</div>
	</div>
</template>


<script>
	import {
		machinesInfo,
		machinesValid,
		machinesInvalid,
		machinesReboot
	} from "@/api/equipment";
	export default {
		data(){
			return{
				code:this.$route.query.code,
				id:this.$route.query.id,
				tableData:[],
				vminfo:{},
				userInfo:{}
			}
		},
		created(){
			this.info();
		},
		methods:{
			isBboolean(state){
				let isBoolean = false
				if(state ==='false' || state ==='true' || state =='0' || state =='1'){
					isBoolean = true
				}
				return isBoolean;
			},
			machinesReboot(data) {
				this.$confirm("确认重启设备？")
					.then(async() => {
						const res = await machinesReboot({
							id: this.id,
						});
						const {
							pageCount,
							results
						} = res;
						this.$message({
							message: "重启成功",
							type: "success",
						});
						this.info();
						
					})
					.catch((_) => {});
			},
			machinesValid(data) {
				this.$confirm("确认恢复销售？")
					.then(async() => {
						const res = await machinesValid({
							id: this.id,
						});
						const {
							pageCount,
							results
						} = res;
						this.$message({
							message: "操作成功",
							type: "success",
						});
						this.info();
					})
					.catch((_) => {});
			},
			machinesInvalid(data) {
				this.$confirm("确认停售设备？")
					.then(async() => {
						const res = await machinesInvalid({
							id: this.id,
						});
						const {
							pageCount,
							results
						} = res;
						this.$message({
							message: "操作成功",
							type: "success",
						});
						this.info();
					})
					.catch((_) => {});
			},
			gowarn(){
				this.$router.push({
					name:'vmWarn',
					query:{
						code:this.code,
						id:this.id
					}
				});
			},
			cancel(){
				this.$router.push('equipment');
			},
			async info() {
				const res = await machinesInfo({
					vmCode:this.code
				});
				const {
					pageCount,
					results
				} = res;
				console.log(res)
				this.vminfo = results[0];
				this.userInfo = results[0].admin;
			}
		},
	}
</script>

<style lang="less" scoped>
	@import "../../assets/css/common/common.less";
	.h1s{
		font-size: 20px;
		font-weight: bold;
		margin: 0px 15px;
	}
	.h1-title{
		font-size: 14px;
		font-weight: bold;
		margin: 15px 0px;
	}
	.vm-states-list p{
		margin: 10px 0px;
	}
</style>
<style lang="less">
	@import "../../assets/css/checkdata/checkdata.less";
</style>